/* Font Family Import */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*-----------------------------------------------------  Color Variables */
:root {
  --primarycolor: #3e8ef7;
  --bs-body-font-size: 12px;
  --bs-body-font-weight: 500;
  --bs-body-font-family: "Montserrat", sans-serif;
  --clientheight: calc(100vh - 65px - 15px - 15px - 30px);
  /*+высота для того чтобы не было прокрутки страницы*/
}

[data-bs-theme=light] {
  --sidebarbg: var(--bs-body-bg);
  --sidebarcolor: var(--bs-body-color);
  /*#6c757d;*/
  --sidebaractivecolor: #1e3d73;
  --sidebarheadingbackground: var(--bs-body-bg);
  --mainbackground: #f6f6f7;
  --bs-border-color-translucent: rgba(72, 94, 144, 0.16);
  --bs-border-color: rgba(72, 94, 144, 0.16);
}

[data-bs-theme=dark] {
  --sidebarbg: var(--bs-body-bg);
  --sidebarcolor: rgba(var(--bs-body-color-rgb), 0.95);
  --sidebaractivecolor: rgba(var(--bs-body-color-rgb), 0.7);
  --sidebarheadingbackground: rgba(var(--bs-body-color-rgb), 0.03);
  --mainbackground: #1d1d1d;
}

/*-----------------------------------------------------  Common Css */

body {
  letter-spacing: 0.3px;
}

body.login {
  background-color: var(--mainbackground);
}

hr {
  border-top: 1px solid var(--bs-border-color-translucent);
  opacity: 1;
}

a {
  text-decoration: none;
}

.spinner-border-sm,
.spinner-grow-sm {
  --bs-spinner-width: 0.875rem;
  --bs-spinner-height: 0.875rem;
}

.btn {
  font-size: inherit;
  font-weight: 500;
}

.form-select {
  background-position: right 0.5rem center;
  /* background-size: 14px 10px; */
  font-size: inherit;
}

.form-control {
  font-size: inherit;
}

[data-bs-theme=dark] .form-control,
[data-bs-theme=dark] .form-select {
  font-weight: inherit;
}

.form-control[readonly] {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

a:focus,
.btn:focus,
.btn-close:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

.form-control:focus,
.form-select:focus {
  /* box-shadow: none; */
  box-shadow: 0 0 0 0.05rem rgba(13, 110, 253, 0.25);
}

.btn-primary,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary.focus,
.btn-primary.disabled,
.btn-primary:disabled:hover {
  background-color: var(--primarycolor) !important;
  border-color: var(--primarycolor);
}

.btn-primary:hover {
  box-shadow: 0 8px 25px -8px black;
}

[data-bs-theme=dark] .btn-primary:hover {
  box-shadow: 0px 3px 10px -3px rgb(253, 253, 253);
}

/*----------------------------------------------------- Pagination */
.pagination {
  --bs-pagination-font-size: inherit;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-bg: var(--primarycolor);
  --bs-pagination-active-border-color: var(--primarycolor);
}

@media (max-width: 576px) {
  .pagination {
    flex-wrap: wrap;
  }
}

/*----------------------------------------------------- Main */
main {
  margin-top: 65px;
  margin-left: 250px;
  padding: 15px 0px;
  min-height: calc(var(--clientheight) + 15px + 15px);
  background-color: var(--mainbackground);
}

@media (max-width: 991px) {
  main {
    margin-left: 0px;
    width: 100%;
  }
}

/*----------------------------------------------------- Footer */
footer.site-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 250px;
  border-top: 1px solid var(--bs-border-color-translucent);
}

@media (max-width: 991px) {
  footer.site-footer {
    margin-left: 0px;
  }
}

/*----------------------------------------------------- Scroll bar color */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background: var(--bs-gray-400);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--bs-gray-500);
}

[data-bs-theme=dark] ::-webkit-scrollbar-track {
  background: var(--bs-gray-700);
}

[data-bs-theme=dark] ::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-800);
}

/* ::-webkit-scrollbar-button:single-button {
  background-color: var(--bs-gray-600);
  border-radius: 1px;
  height: 10px;
} */

/*----------------------------------------------------- Back to top */
.scrollup {
  width: 38px;
  height: 38px;
  position: fixed;
  bottom: 30px;
  right: 16px;
  display: none;
  z-index: 999;
  color: #fff;
  border-radius: 5px;
  line-height: 38px;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--primarycolor);
  box-shadow: 0 1px 20px 1px var(--primarycolor);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
}

/*----------------------------------------------------- Template Settings */
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#settings.offcanvas {
  --bs-offcanvas-width: 350px;
}

.setting {
  background-color: var(--primarycolor);
  width: 35px;
  height: 35px;
  position: fixed;
  top: 45%;
  right: 0px;
  transition: all 0.3s;
  z-index: 999;
  color: #fff !important;
  line-height: 35px;
  text-align: center;
  font-size: 25px;
  -webkit-border-top-left-radius: 50px !important;
  -webkit-border-bottom-left-radius: 50px !important;
  -moz-border-radius-topleft: 50px !important;
  -moz-border-radius-bottomleft: 50px !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  caret-color: transparent !important;
}

.setting h5 {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.setting h5 i {
  line-height: 35px;
}

#settings .color-box {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

#settings input.color {
  width: 40px;
  height: 40px;
}


/*-----------------------------------------------------  Top Header */
[data-bs-theme=dark] #header-fix {
  /* border-bottom: 1px solid var(--bs-border-color-translucent); */
  box-shadow: 0 4px 6px 0 rgba(170, 170, 170, 0.1), 0 1px 20px 0 rgba(255, 255, 255, 0.07), 0px 1px 11px 0px rgba(255, 255, 255, 0.07);
}

[data-bs-theme=light] .vertical-menu #header-fix .logo-bar[data-bs-theme=dark],
[data-bs-theme=light] .compact-menu #header-fix .logo-bar[data-bs-theme=dark] {
  border-bottom: 1px solid var(--bs-border-color-translucent);
  /* box-shadow: 0 4px 6px 0 rgba(170, 170, 170, 0.1), 0 1px 20px 0 rgba(255, 255, 255, 0.07), 0px 1px 11px 0px rgba(255, 255, 255, 0.07);   */
  background-color: var(--bs-body-bg);
}

#header-fix {
  left: 0px;
  z-index: 10;
  transition: all 0.5s;
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  background-color: var(--bs-body-bg);
}

#header-fix .logo-bar {
  width: 250px;
  padding: 7px 7px;
  border-right: 1px solid var(--bs-border-color-translucent);
  transition: all 0.5s;
}

#header-fix .logo-bar .horizontal-logo {
  line-height: 31px;
}

#header-fix .logo-bar .horizontal-logo svg {
  margin-top: -3px;
}

#header-fix .logo-small {
  display: none;
}

@media (max-width: 991px) {
  #header-fix .logo-bar {
    width: 120px;
  }
}

@media (max-width: 460px) {
  #header-fix .logo-bar {
    width: 50px !important;
  }

  #header-fix .logo-bar span {
    display: none;
  }

  #header-fix .logo-large {
    display: none;
  }

  #header-fix .logo-small {
    display: flex;
  }

}

#header-fix .collapse-menu-bar {
  padding-top: 2px;
  line-height: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-right: 1px solid var(--bs-border-color-translucent);
}

#header-fix nav {
  height: 65px;
}

#header-fix a:not(.dropdown-item) {
  color: var(--bs-body-color);
}

#header-fix .search-form {
  width: 35%;
}

#header-fix .search-form .form-control {
  font-size: 12px;
}

#header-fix .search-form .form-control::placeholder {
  opacity: 0.8;
}

#header-fix .search-form .form-control:-ms-input-placeholder {
  opacity: 0.8;
}

#header-fix .search-form .form-control::-ms-input-placeholder {
  opacity: 0.8;
}

#header-fix .top-icon>li {
  padding: 18px 9px;
  border-left: 1px solid var(--bs-border-color-translucent);
  height: 100%;
}

@media (max-width: 440px) {
  #header-fix .top-icon>li {
    padding: 18px 7px;
  }
}

#header-fix .top-icon>li:first-child {
  border-left: none;
}

@media (max-width: 991px) {
  #header-fix .top-icon>li>a {
    padding: 8px;
  }
}

#header-fix .top-icon>li>a>i {
  font-size: 20px;
  line-height: 14px;
}

@media (max-width: 991px) {
  #header-fix {
    left: 0px;
  }

  #header-fix .search-form {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  #header-fix .search-form .form-group {
    height: 100%;
  }

  #header-fix .search-form .form-group .form-control {
    border-radius: 0rem !important;
    height: 100%;
  }

  #header-fix .search-form .form-group .btn-search {
    top: 24px;
  }

  #header-fix .search-form .form-group .close-button {
    right: 10px;
    top: 24px;
  }
}

#header-fix .btn-search {
  top: 8px;
  left: 15px;
}

#header-fix .badge {
  position: absolute;
  top: 18px;
  right: 18px;
  font-weight: 300;
  padding: 3px 6px;
}

#header-fix .ring {
  position: absolute;
  top: -13px;
  right: -7px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid var(--bs-body-color);
  border-radius: 100%;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0;
}

#header-fix .ring-point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 100%;
  background-color: var(--bs-body-color);
  position: absolute;
  right: 3px;
  top: -3px;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

#header-fix .dropdown-menu {
  font-size: 13px;
  min-width: 150px;
  border-top: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 10px;
}

/* #header-fix .dropdown-menu:after {
    content: '';
    position: absolute;
    top: -9px;
    right: 25px;
    border-bottom: 9px solid var(--bs-body-color);
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    display: none;
} */

/* #header-fix .dropdown-menu.left:before {
    right: auto;
    left: 22px;
}

#header-fix .dropdown-menu.left:after {
    right: auto;
    left: 23px;
} */

/* #header-fix .user-profile {
    background: rgba(255, 255, 255, 0.1);
}

#header-fix .user-profile .user-info {
    line-height: 11px;
    margin-left: 7px;
    font-size: 12px;
} */

.header-chart {
  height: 100px;
  width: 200px;
  float: right;
}

/*----------------------------------------------------- Sidebar Menu left */
.sidebar {
  background-color: var(--sidebarbg);
  position: fixed;
  top: 65px;
  bottom: 0;
  z-index: 5;
  width: 250px;
  transition: all 0.5s;
  border-right: 1px solid var(--bs-border-color-translucent);
  border-top: 0px solid var(--bs-border-color-translucent);
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

@media (max-width: 991px) {
  .sidebar {
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px);
  }

  .sidebar.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin-left: 0px;
    z-index: 5;
  }
}

.sidebar a.disabled {
  color: #6c757d !important;
  opacity: 0.6 !important;
  pointer-events: none;
  cursor: default;
}

.sidebar .sidebar-menu {
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
  list-style: none;
}

.sidebar .sidebar-menu .sub-menu {
  opacity: 0;
  display: none;
}

.sidebar .sidebar-menu>li {
  padding: 5px 15px;
}

.sidebar .sidebar-menu>li a:hover {
  color: var(--sidebaractivecolor);
}

.sidebar .sidebar-menu>li a.active {
  color: var(--sidebaractivecolor);
}

.sidebar .sidebar-menu>li>a {
  font-weight: 600;
  pointer-events: none;
  font-size: 12px;
  text-transform: uppercase;
  background-color: var(--sidebarheadingbackground);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: block;
  padding: 10px;
  margin: 5px 0px;
  color: var(--sidebarcolor);
}

.sidebar .sidebar-menu>li>a:after {
  display: none;
}

.sidebar .sidebar-menu>li ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.sidebar .sidebar-menu>li ul li {
  padding: 5px 0px;
}

.sidebar .sidebar-menu>li ul li i {
  margin-right: 15px;
}

.sidebar .sidebar-menu>li ul li a {
  color: var(--sidebarcolor);
  display: block;
  font-size: 13px;
  font-weight: 500;
}

.sidebar .sidebar-menu>li ul li a i {
  font-weight: 700;
}

.sidebar .sidebar-menu>li ul li.dropdown>a {
  position: relative;
}

.sidebar .sidebar-menu>li ul li.dropdown>a:after {
  content: "\e606";
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 10px;
}

.sidebar .sidebar-menu>li ul li.active>a:after {
  transform: rotate(90deg);
}

.sidebar .sidebar-menu>li ul li.active>.sub-menu {
  opacity: 1;
  display: block;
}

.sidebar .sidebar-menu>li ul li .sub-menu {
  padding-left: 28px;
}

.sidebar .sidebar-menu>li ul li .sub-menu a {
  font-weight: 500;
}

.sidebar .sidebar-menu>li ul li .sub-menu i {
  display: none;
}

.sidebar .sidebar-menu>li ul li .sub-menu .sub-menu {
  padding-left: 10px;
}

.sidebar .sidebar-menu>li ul li .sub-menu .sub-menu a {
  font-weight: 400;
}

.sidebar .breadcrumb {
  display: none;
}

/*----------------------------------------------------- Page Loader */
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /* background-color: var(--mainbackground); */
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--primarycolor);
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-----------------------------------------------------  Compact Menu */
.compact-menu:not(.horizontal-menu) #header-fix .logo-bar {
  width: 140px;
}

@media (min-width: 992px) {
  .compact-menu:not(.horizontal-menu) {
    position: relative;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) {
    position: absolute;
    width: 80px;
    height: 100%;
    z-index: 2;
    overflow: scroll;
    top: 0px;
    padding-top: 0px;
  }
}

@media (min-width: 992px) and (max-width: 991px) {
  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) {
    -webkit-transform: translateX(-80);
    transform: translateX(-80);
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover).active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu {
    padding-top: 0px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li {
    padding: 0px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>ul {
    margin: 0px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>ul>li {
    padding: 20px 0px;
    text-align: center;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>ul>li>a {
    line-height: 0px;
    font-size: 0px;
    display: block;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>ul>li>a:after {
    display: none;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>ul>li>a i {
    font-size: 20px;
    margin-right: 0px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>a {
    line-height: 0px;
    font-size: 0px;
    display: block;
    background-color: transparent;
    padding: 0px;
    box-shadow: none;
    margin: 0px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li>a i {
    font-size: 0px;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu>li ul li.active>.sub-menu {
    display: none;
  }
}

@media (min-width: 992px) {
  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li:hover>.sub-menu {
    display: block !important;
    position: absolute;
    left: 100%;
    top: 0px;
    min-width: 190px;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li:hover>.sub-menu li a {
    line-height: 13px;
    display: flex;
    white-space: nowrap;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li:hover>.sub-menu li a i {
    display: block;
    float: left;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li .sub-menu {
    background-color: var(--sidebarbg);
    margin: 0px;
    text-align: left;
    border: 1px solid var(--bs-border-color-translucent);
    padding-left: 0px !important;
    border-bottom: none;
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li .sub-menu li {
    padding: 15px;
    border-bottom: 1px solid var(--bs-border-color-translucent);
  }

  .compact-menu:not(.horizontal-menu) .sidebar:not(:hover) .sidebar-menu li .sub-menu li.dropdown>a:after {
    top: 3px;
  }

  .compact-menu:not(.horizontal-menu) main,
  .compact-menu:not(.horizontal-menu) .site-footer {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
}

@media (min-width: 992px) and (max-width: 991px) {

  .compact-menu:not(.horizontal-menu) main,
  .compact-menu:not(.horizontal-menu) .site-footer {
    margin-left: 0px;
  }
}

/*-----------------------------------------------------  horizontal Menu */
.horizontal-menu {
  --clientheight: calc(100vh - 65px - 41px - 15px - 15px - 30px);
  /*+высота для того чтобы не было прокрутки страницы*/
}

.horizontal-menu main,
.horizontal-menu .site-footer {
  margin-left: 0px;
  width: 100%;
}

.horizontal-menu .site-width {
  /* max-width: 1200px; */
  width: 100%;
  margin: 0 auto;
  display: inherit;
}

.horizontal-menu #header-fix {
  z-index: 10;
  box-shadow: none;
  border-bottom: 1px solid var(--bs-border-color-translucent);
}

.horizontal-menu #header-fix .logo-bar {
  border-right: 1px solid var(--bs-border-color-translucent);
}

@media (min-width: 992px) {
  .horizontal-menu #header-fix .collapse-menu-bar {
    display: none;
  }
}

.horizontal-menu #header-fix .top-icon>li {
  border-left: 1px solid var(--bs-border-color-translucent);
}

@media (min-width: 992px) {
  .horizontal-menu .sidebar {
    background-color: var(--sidebarbg);
    position: relative;
    width: 100%;
    padding: 25px 20px 0px 15px;
    display: flex;
    overflow: inherit;
    z-index: 2;
    top: 65px;
    border-bottom: 1px solid var(--bs-border-color-translucent);
    border-right: none;
  }

  .horizontal-menu .sidebar .sidebar-menu {
    margin-bottom: -1px;
    padding: 0px;
  }

  .horizontal-menu .sidebar .sidebar-menu>li {
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid var(--sidebarbg);
    border-bottom: none;
  }

  /* .horizontal-menu .sidebar .sidebar-menu>li.active,
    .horizontal-menu .sidebar .sidebar-menu>li:hover {
        border: 1px solid var(--bs-border-color-translucent);
        background-color: var(--sidebarheadingbackground);
    } */

  .horizontal-menu .sidebar .sidebar-menu>li.active>a,
  .horizontal-menu .sidebar .sidebar-menu>li:hover>a {
    color: var(--sidebaractivecolor);
  }

  .horizontal-menu .sidebar .sidebar-menu>li>a {
    font-weight: 600;
    pointer-events: inherit;
    font-size: 12px;
    text-transform: initial;
    color: var(--sidebarcolor);
    box-shadow: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    line-height: 20px;
  }

  .horizontal-menu .sidebar .sidebar-menu>li ul {
    z-index: 10 !important;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: var(--bs-body-bg) !important;
    border: 1px solid var(--bs-border-color-translucent);
    left: -1px;
    min-width: 200px;
    transition: all .5s;
    top: 120%;
    margin-top: 0px;
  }

  .horizontal-menu .sidebar .sidebar-menu>li ul li a {
    font-weight: 500;
    /* color: var(--dropdowncolor); */
  }

  .horizontal-menu .sidebar .sidebar-menu>li ul li a:hover {
    color: var(--sidebaractivecolor);
  }

  .horizontal-menu .sidebar .sidebar-menu>li ul li.active>a {
    color: var(--sidebaractivecolor);
  }

  .horizontal-menu .sidebar .sidebar-menu>li ul li.active>a:after {
    transform: rotate(0deg);
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li {
    padding: 10px 10px;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li ul,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li ul {
    left: 100%;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li:hover>ul,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li:hover>ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li:hover>ul.sub-menu,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li:hover>ul.sub-menu {
    top: -1px;
    left: 100%;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li+li,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li+li {
    border-top: 1px solid var(--bs-border-color-translucent);
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li .sub-menu,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li .sub-menu {
    display: block;
    padding-left: 0px;
  }

  .horizontal-menu .sidebar .sidebar-menu>li:hover>ul li .sub-menu i,
  .horizontal-menu .sidebar .sidebar-menu>li li:hover>ul li .sub-menu i {
    display: initial;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .horizontal-menu .sidebar .breadcrumb {
    display: flex;
    margin-right: 15px !important;
  }
}

@media (min-width: 992px) {
  .horizontal-menu .sidebar .breadcrumb .breadcrumb-item {
    color: var(--sidebarcolor);
  }

  .horizontal-menu .sidebar .breadcrumb .breadcrumb-item.active,
  .horizontal-menu .sidebar .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: var(--sidebarcolor);
    content: "\e606";
  }

  .horizontal-menu .sidebar .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    font-size: 10px;
    font-family: 'simple-line-icons';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .horizontal-menu .sidebar .breadcrumb a {
    color: var(--sidebarcolor);
  }
}

/* Responsive Css */
/* @media (max-width: 767px) {
    .card .card-header h4 {
        font-size: 1rem;
        font-weight: bold;
    }

    .timeline-point:after, .timeline-point:before, .timeline:after, .timeline:before {
        display: none;
    }

    .header-chart {
        float: left;
    }
} */

/*-----------------------------------------------------  Card */
.card:not(.login) {
  --bs-card-spacer-y: 15px;
  --bs-card-spacer-x: 15px;
  --bs-card-title-spacer-y: 0px;
  --bs-card-cap-padding-y: 15px;
  --bs-card-cap-padding-x: 15px;
  --bs-card-cap-bg: var(--bs-body-bg);
}

/* .card .card-body {
    padding: 15px;
}

.card .card-header,
.card .card-footer {
    background-color: transparent;
    padding: 15px;
}
*/
.card .card-header h4 {
  line-height: 1;
  font-size: 1rem;
}

/* .card .card-header .card-title {
    margin-bottom: 0px;
} */


/*-----------------------------------------------------  Checkbox Radio Button */
.chkbox {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
  line-height: 17px;
  margin-bottom: 0px;
}

.chkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.chkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid var(--bs-border-color-translucent);
  border-radius: 3px;
}

.chkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.chkbox.smallchk {
  padding-left: 19px;
  line-height: 17px;
}

.chkbox.smallchk .checkmark {
  height: 14px;
  width: 14px;
  top: calc(50% - 8px);
  border-radius: 0px;
}

.chkbox.smallchk .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
}

.chkbox input[type=radio]~.checkmark {
  border-radius: 25px;
}

.chkbox:hover input[type=radio]~.checkmark {
  border-radius: 25px;
}

.chkbox input:checked~.checkmark {
  background-color: var(--primarycolor);
  border-color: var(--primarycolor);
}

.chkbox input:checked+b,
.chkbox input:checked+img+img+span,
.chkbox input:checked+img+span,
.chkbox input:checked+span,
.chkbox input:checked+p {
  text-decoration: line-through;
}

.chkbox input[type=radio]:checked~.checkmark {
  border-radius: 25px;
}

.chkbox input:checked~.checkmark:after {
  display: block;
}

.chkbox input[type=radio]:checked~.checkmark:after {
  top: -2px;
  left: -2px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  background: var(--primarycolor);
}