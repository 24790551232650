.logo-bar {
  display: flex;
  align-items: center;
}
.logo-bar .logotext {
  white-space: normal;
  text-align: center;
  font-size: 1.8rem;
  flex-grow: 1;
}
.rowcols {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.rowcols .rowcol {
  width: 100%;
}
.rowcols .rowcol.checkbox {
  width: 20%;
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  align-items: flex-end;
}
.rowcols .rowcol.checkbox .actcheck {
  width: 15px;
  height: 15px;
  padding: 0%;
  margin-bottom: 10px;
}
.rowcols .rowcol.flag {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
